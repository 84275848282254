var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-3"},[_c('h2',[_vm._v("Test")]),_c('div',{staticClass:"row"},[_c('StatisticsCardMultiple',{staticClass:"col-6",attrs:{"itemsList":[
        { title: this.$t('statistics.totalDebts'), value: '١٠٠٠ جنيه مصري' },
        { title: this.$t('statistics.totalPaid'), value: '٣٠٠ جنيه مصري' },
        {
          title: this.$t('statistics.totalRemainder'),
          value: '٧٠٠ جنيه مصري',
        } ],"percentageList":[
        { title: this.$t('statistics.totalPaid'), label: '40%', value: 40 },
        {
          title: this.$t('statistics.totalRemainder'),
          label: '60%',
          value: 60,
        } ]}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }